<template>
  <div class="mod-my-org-freezer">
    <amis-renderer :schema="schema" />
  </div>
</template>

<script>
import AmisRenderer from '@/components/AmisRenderer';
import schema from './schemas/my-org-freezer.json';

export default {
  name: 'MyOrgFreezer',
  components: { AmisRenderer },
  data() {
    return {
      schema,
    };
  },
};
</script>

<style lang="scss" scoped>
.mod-my-org-freezer {
  ::v-deep .org-freezer-aside {
    width: 300px !important;
  }
}
</style>
